.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-container a {
  text-decoration: none;
}

.landing-button {
  text-align: center;

  font-size: 20px;
  margin-top: 30px;
  width: 200px;
}

.landing-app-name {
  font-size: 80px;
  font-weight: 300;

  margin-top: 0;
  margin-bottom: 20px;
}

.landing-tagline {
  font-size: 24px;
  font-weight: 300;

  margin-top: 0;
  margin-bottom: 5px;

  text-align: center;
}

.view-sample-button {
  margin-top: 40px;
}

.mailing-list-signup-container {
  margin-top: 40px;
  font-size: 20px;
  max-width: 280px;
  text-align: center;
}
