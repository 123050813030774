.title-line {
  margin-top: -18px;
  display: flex;
}

.entry-container--large-font .title-line {
  margin-top: -24px;
}

.todo-keyword {
  font-weight: bold;
  margin-right: 5px;
}

.todo-keyword--todo {
  color: red;
}

.todo-keyword--done {
  color: green;
}

.todo-keyword--none {
  color: lightgray;
}

.header-tag {
  background-color: white;
  border: 1px solid lightgray;
  padding: 1px 2px;
  margin: 2px;
  display: inline-block;
  border-radius: 5px;
}

.title-line__edit-container {
  width: 100%;
}

.title-line__insert-timestamp-button {
  font-family: sans-serif;
}

.title-line__insert-timestamp-button {
  color: gray;

  font-family: Courier;

  display: flex;
  align-items: center;
}

.insert-timestamp-icon {
  margin-right: 5px;
}
