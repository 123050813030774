.header-list-container {
  font-family: Courier;
  margin-top: 10px;

  z-index: 1;
}

.header-list-container--focused {
  box-shadow: inset 0px 0px 5px 0px rgba(148, 148, 148, 0.75);
  margin: 0;
  margin-top: 10px;
  border-radius: 5px;
}
