body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.btn {
    background-color: #5E348C;
    color: white;
    border: 0;
    padding: 8px 16px;
}

.btn--circle {
    border-radius: 50%;
    font-weight: bold;
    padding: 0;
    width: 60px;
    height: 60px;
}

.btn--disabled {
    background-color: lightgray;
}

.nice-scroll {
    -webkit-overflow-scrolling: touch;
}

.textarea {
    width: 95%;
    font-size: 16px;
    font-family: Courier;

    -webkit-appearance: none;
    border-radius: 0;
}

.textfield {
    border: 1px solid #D8D8D8;
    border-radius: 0;
    -webkit-appearance: none;
    padding: 5px;
    font-size: 16px;
}

div[tabindex="-1"]:focus {
    outline: 0;
}

button {
    touch-action: manipulation;
}

.entry-container {
  font-size: 16px;

  overflow-x: hidden;
}

.entry-container--large-font {
  font-size: 20px;
}

.privacy-policy-container {
  margin-left: 10px;
}

.header-bar {
  background-color: #5e348c;
  height: 30px;
  padding: 10px;

  display: grid;
  grid-template-columns: 5fr 3fr 5fr;
  -webkit-align-items: center;
          align-items: center;
}

.header-bar--with-logo {
  grid-template-columns: 5fr 1fr 5fr;
}

.header-bar__logo-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.header-bar__logo {
  width: 30px;
}

.header-bar__app-name {
  color: white;
  margin: 0;
  margin-left: 5px;
}

.header-bar__title {
  color: white;
  text-align: center;
  font-size: 20px;
}

.header-bar__back-button {
  color: white;
  text-decoration: none;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.header-bar__back-button__directory-path {
  margin-left: 5px;
}

.header-bar__actions {
  margin-left: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;

  color: white;
}

.header-bar__actions__item {
  color: white;

  margin-left: 20px;
}

.settings-icon--has-unseen-changelog {
  color: #ff5b5b;
}

.header-bar__actions__item--disabled {
  opacity: 0.5;
}

.landing-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.landing-container a {
  text-decoration: none;
}

.landing-button {
  text-align: center;

  font-size: 20px;
  margin-top: 30px;
  width: 200px;
}

.landing-app-name {
  font-size: 80px;
  font-weight: 300;

  margin-top: 0;
  margin-bottom: 20px;
}

.landing-tagline {
  font-size: 24px;
  font-weight: 300;

  margin-top: 0;
  margin-bottom: 5px;

  text-align: center;
}

.view-sample-button {
  margin-top: 40px;
}

.mailing-list-signup-container {
  margin-top: 40px;
  font-size: 20px;
  max-width: 280px;
  text-align: center;
}

.file-browser-container a {
  text-decoration: none;
  color: black;
}

.file-browser__header {
  margin: 5px;
}

.file-browser__file-list {
  list-style-type: none;
  padding-left: 0;
}

.file-browser__file-list__icon {
  color: #5e348c;
}

.file-browser__file-list__element {
  border-top: 1px solid #d8d8d8;
  padding: 10px;
  font-family: Courier;
}

.file-browser__file-list__element:last-of-type {
  border-bottom: 1px solid #d8d8d8;
}

.file-browser__file-list__element--load-more-row:last-of-type {
  border-bottom: none;
  text-align: center;
  color: #5e348c;
  font-weight: bold;
}

.file-browser__file-list__loading-more-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;

  color: #5e348c;

  margin-top: 10px;
}

.file-browser__file-list__icon--not-org {
  color: lightgray;
}

.file-browser__file-list__icon--directory {
  color: #5e348c;
}

.loading-indicator {
  color: white;
  background-color: #5e348c;
  font-weight: bold;
  opacity: 0.9;
  padding: 18px;
  display: inline-block;
  position: fixed;
  font-size: 20px;
  left: 50%;
  top: 85px;
  width: 80%;
  text-align: center;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1;
}

.org-file__parsing-error-message {
  text-align: center;
  font-size: 16px;

  margin: 10px;
}

.org-file__btn {
  margin: 10px;
  text-align: center;
}

.dirty-indicator {
  padding: 3px;
  background-color: gray;
  opacity: 0.5;
  color: white;
  position: fixed;
  bottom: 100px;
  right: 10px;
  font-weight: bold;
}

.error-message-container {
  padding: 10px;
  font-size: 20px;
}

.header-list-container {
  font-family: Courier;
  margin-top: 10px;

  z-index: 1;
}

.header-list-container--focused {
  box-shadow: inset 0px 0px 5px 0px rgba(148, 148, 148, 0.75);
  margin: 0;
  margin-top: 10px;
  border-radius: 5px;
}

.header {
  margin-bottom: 2px;
  margin-top: 5px;
  padding-top: 5px;
  position: relative;
  overflow-y: visible;
  overflow-x: visible;
  min-height: 23px;
}

.header--selected {
  background-color: rgba(239, 255, 0, 0.28);
}

.header--removing {
  overflow-y: hidden;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
  margin-top: -2px;
}

.left-swipe-action-container {
  top: 0;
  right: 100%;
  height: 100%;
  position: absolute;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.right-swipe-action-container {
  top: 0;
  right: 0;
  height: 100%;
  position: absolute;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.swipe-action-container__icon {
  position: absolute;
  color: white;
}

.swipe-action-container__icon--left {
  right: 10px;
}

.swipe-action-container__icon--right {
  left: 10px;
}

.title-line {
  margin-top: -18px;
  display: -webkit-flex;
  display: flex;
}

.entry-container--large-font .title-line {
  margin-top: -24px;
}

.todo-keyword {
  font-weight: bold;
  margin-right: 5px;
}

.todo-keyword--todo {
  color: red;
}

.todo-keyword--done {
  color: green;
}

.todo-keyword--none {
  color: lightgray;
}

.header-tag {
  background-color: white;
  border: 1px solid lightgray;
  padding: 1px 2px;
  margin: 2px;
  display: inline-block;
  border-radius: 5px;
}

.title-line__edit-container {
  width: 100%;
}

.title-line__insert-timestamp-button {
  font-family: sans-serif;
}

.title-line__insert-timestamp-button {
  color: gray;

  font-family: Courier;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.insert-timestamp-icon {
  margin-right: 5px;
}

.attributed-string__cookie-part {
  font-weight: bold;
  color: red;
}

.attributed-string__cookie-part--complete {
  color: green;
}

.attributed-string__inline-markup--inline-code {
  color: black;
  background-color: #eaeaea;
  border: 1px solid gray;
  padding: 2px;
}

.attributed-string__inline-markup--bold {
  font-weight: bold;
}

.attributed-string__inline-markup--italic {
  font-style: italic;
}

.attributed-string__inline-markup--strikethrough {
  text-decoration: line-through;
}

.attributed-string__inline-markup--underline {
  text-decoration: underline;
}

.table-part {
  border-collapse: collapse;
  margin-top: 3px;
  margin-bottom: 3px;
}

.table-part__cell {
  border: 1px solid lightgray;

  white-space: pre;

  vertical-align: top;
}

.table-part__cell--selected {
  background-color: rgba(239, 255, 0, 0.28);
}

.table-cell__edit-container {
  width: 100%;
}

.table-cell__insert-timestamp-button {
  color: gray;

  font-family: Courier;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.insert-timestamp-icon {
  margin-right: 5px;
}

.table-action-drawer-container {
  color: lightgray;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.table-action-drawer__edit-icon-container {
  grid-row-start: span 2;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.table-action-drawer__sub-icon-container {
  position: relative;
  margin: 15px 0;
}

.table-action-drawer__main-icon {
  padding-right: 3px;
  padding-bottom: 3px;
}

.table-action-drawer__sub-icon {
  position: absolute;
  top: 10px;
  left: 15px;
}

.table-action-drawer__sub-icon--rotated {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.attributed-string__list-part {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 20px;
}

.attributed-string__list-part--ordered {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 40px;
}

.list-part__checkbox-container {
  line-height: 22px;
}

.checkbox {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  margin-right: 5px;

  border: 2px solid #5e348c;
  border-radius: 2px;
  box-sizing: border-box;

  display: inline-block;

  color: white;
}

.checkbox__inner-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.attributed-string__timestamp-part {
  color: blue;
  text-decoration: underline;
}

.header-content-container {
  white-space: pre-wrap;
  overflow-x: auto;
}

.header-content__edit-icon {
  color: #5e348c;

  margin-top: 10px;
  margin-right: 5px;
  float: left;
}

.header-content__insert-timestamp-button {
  color: gray;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.insert-timestamp-icon {
  margin-right: 5px;
}

.planning-items__item-container {
  display: -webkit-flex;
  display: flex;
}

.planning-item__type {
  color: gray;
}

.planning-item__timestamp {
  color: blue;
  text-decoration: underline;
}

.property-list__property {
  font-weight: bold;
  color: rgb(177, 134, 16);

  margin-right: 5px;
}

.property-list__item-container {
  display: -webkit-flex;
  display: flex;
}

.header-action-drawer-container {
  color: lightgray;

  padding-bottom: 10px;

  padding-right: 20px;
}

.header-action-drawer__row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;

  padding-top: 10px;
}

.header-action-drawer__ff-click-catcher-container {
  position: relative;
}

.header-action-drawer__ff-click-catcher {
  height: 20px;
  width: 20px;

  position: absolute;
  top: 0;
  left: 0;
}

.header-action-drawer__separator {
  background-color: lightgray;

  height: 15px;
  width: 1px;

  margin-left: 10px;
  margin-right: 10px;
}

.header-action-drawer__deadline-scheduled-button {
  text-transform: uppercase;
  width: 90px;
  text-align: center;
}

.action-drawer-container {
  position: fixed;
  bottom: 20px;
  left: 10px;
  right: 10px;
  white-space: nowrap;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;

  z-index: 2;
}

.action-drawer__btn {
  box-shadow: 2px 2px 5px 0px rgba(148, 148, 148, 0.75);

  position: relative;

  -webkit-transition-property: opacity;

  transition-property: opacity;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
}

.action-drawer__done-btn {
  font-weight: bold;
  font-size: 18px;

  height: 60px;
  width: 100%;
}

.action-drawer__arrow-buttons-container {
  position: relative;
}

.action-drawer__arrow-button {
  position: absolute;
  z-index: 0;
}

.action-drawer__main-arrow-button {
  position: relative;
  z-index: 1;
}

.action-drawer__capture-buttons-container {
  position: relative;
}

.action-drawer__btn--letter {
  font-size: 24px;
}

.action-drawer__btn--with-sub-icon {
  padding-right: 3px;
  padding-bottom: 3px;
}

.action-drawer__btn__sub-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.action-drawer__btn__sub-icon--rotated {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.capture-modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.capture-modal-header-path {
  font-family: Courier;
  color: gray;

  margin-top: 5px;
}

.capture-modal-header-path--top-level {
  font-style: italic;
}

.capture-modal-textarea {
  border: 1px solid lightgray;
  font-family: Courier;

  margin-top: 10px;

  width: calc(100% - 5px);

  -webkit-appearance: none;
  border-radius: 0;
}

.capture-modal-button-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;

  margin-top: 10px;
}

.capture-modal-prepend-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.capture-modal-prepend-label {
  margin-right: 10px;
}

.capture-modal-button {
  font-size: 16px;
}

.capture-modal-error-message {
  color: red;

  text-align: center;

  margin-top: 10px;
}

.switch {
  display: inline-block;
  border: 1px solid #d8d8d8;
  width: 90px;
  height: 34px;
  padding: 2px;
  box-sizing: border-box;
}

.switch__grabber {
  width: 42px;
  height: 28px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  box-shadow: 1px 1px 5px 0px rgba(148, 148, 148, 0.75);
  background-color: white;
}

.drawer-outer-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 3;
}

.drawer-outer-container--visible {
  background-color: rgba(94, 52, 140, 0.1);
}

.drawer-inner-container {
  position: fixed;
  bottom: -1px;
  left: -1px;
  right: -1px;

  padding: 15px 10px 0 10px;

  box-shadow: 0px -1px 5px 0px rgba(148, 148, 148, 0.55);
  border-bottom: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  background-color: white;

  max-height: 80%;
  overflow: auto;
}

.drawer__close-button {
  position: absolute;
  top: 15px;
  right: 15px;

  color: #5e348c;

  border: 0;
  background-color: transparent;
  padding: 0;
}

.drawer__grabber {
  width: 35px;
  height: 5px;
  background-color: gray;

  border-radius: 50px;

  margin-left: auto;
  margin-right: auto;

  margin-top: -5px;
  margin-bottom: 5px;
}

.drawer-modal__title {
  color: #5e348c;

  margin-top: 0;
}

.sync-confirmation-modal__header {
  color: #5e348c;

  margin-top: 0;
}

.sync-confirmation-modal__last-sync-time {
  color: #5e348c;

  text-align: center;
  font-weight: bold;
}

.sync-confirmation-modal__buttons-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;

  margin-top: 20px;
  margin-bottom: 10px;
}

.sync-confirmation-modal__button {
  font-size: 18px;

  margin-top: 15px;
}

.no-tags-message {
  color: gray;
  text-align: center;
}

.tag-container {
  display: -webkit-flex;
  display: flex;

  padding: 3px 5px;
}

.tag-container--dragging {
  background-color: #e6e6e6;
}

.tag-container__drag-handle {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.tag-container__textfield {
  -webkit-flex: 3 1;
          flex: 3 1;
}

.tag-container__actions-container {
  -webkit-flex: 1 1;
          flex: 1 1;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;

  padding-left: 10px;

  color: #5e348c;
}

.tags-editor__add-new-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;

  margin-top: 10px;
}

.tags-editor__separator {
  width: calc(100% - 20px);
  margin-top: 20px;
  margin-bottom: 20px;

  border: none;
  border-bottom: 1px solid #d8d8d8;
}

.all-tags-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;

  margin-bottom: 15px;
}

.all-tags__tag {
  color: white;
  background-color: #5e348c;

  padding: 8px;
  margin: 5px;
}

.all-tags__tag--in-use {
  background-color: lightgray;
}

.timestamp-editor__title {
  color: #5e348c;

  margin-top: 0;
}

.timestamp-editor__button-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.timestamp-editor__add-new-button {
  font-size: 18px;
  margin-top: 15px;
}

.timestamp-editor__separator {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;

  margin-top: 10px;
  margin-bottom: 10px;

  font-size: 22px;
}

.timestamp-editor__separator__margin-line {
  background-color: black;
  height: 1px;
  width: 40%;

  margin-top: 5px;
}

.timestamp-editor__render {
  font-family: Courier;
  font-size: 14px;

  text-align: center;
}

.timestamp-editor__date-time-fields-container {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
}

.timestamp-editor__field-container {
  margin-top: 10px;
}

.timestamp-editor__field-container--inline {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.timestamp-editor__field-title {
  text-transform: uppercase;
  font-size: 12px;
  color: #5E348C;

  margin-bottom: 5px;
}

.timestamp-editor__field {
  font-family: Courier;

  -webkit-flex: 1 1;

          flex: 1 1;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.timestamp-editor__icon {
  color: gray;
}

.delay-repeater-value-input {
  width: 30px;
  height: 22px;

  margin-left: 10px;
  margin-right: 10px;
}

.timestamp-editor__icon--add {
  height: 34px;
  padding-right: 12px;
  padding-left: 12px;

  background-color: #5E348C;
  color: white;

  font-size: 14px;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.timestamp-editor__date-input, .timestamp-editor__time-input {
  background-color: #5E348C;
  color: white;

  border: none;
  border-radius: 0;
  font-size: 12px;
  padding: 8px 0 8px 16px;
  margin: 0;
  height: 18px;

  -webkit-appearance: none;
}

.timestamp-editor__icon--remove {
  color: #5E348C;
  margin-left: 15px;
}

.timestamp-editor__delay-repeater-type {
  min-width: 95px;
}

.tab-buttons {
  border: 1px solid #d8d8d8;
  height: 34px;
  box-sizing: border-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
}

.tab-buttons--equal-width-tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.tab-buttons__btn {
  box-sizing: border-box;
  height: 100%;
  padding: 7px;
  border-right: 1px solid lightgray;

  text-align: center;
}

.tab-buttons__btn:last-of-type {
  border-right: none;
}

.entry-container--large-font .tab-buttons__btn {
  padding: 5px;
}

.tab-buttons__btn--selected {
  background-color: #5e348c;
  color: white;
}

.no-items-message {
  color: gray;
  text-align: center;
}

.property-list-editor__item-container {
  display: -webkit-flex;
  display: flex;

  padding: 3px 5px;
}

.property-list-editor__item-container--dragging {
  background-color: #e6e6e6;
}

item-container__drag-handle {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.item__textfields-container {
  -webkit-flex: 4 1;
          flex: 4 1;
  display: -webkit-flex;
  display: flex;
}

.item-container__textfield {
  margin-right: 10px;
  width: 40%;
}

.item-container__actions-container {
  -webkit-flex: 1 1;
          flex: 1 1;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;

  color: #5e348c;
}

.property-list-editor__add-new-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;

  margin-top: 10px;
  margin-bottom: 10px;
}

.agenda__title {
  color: #5e348c;

  margin-top: 0;
}

.agenda__tab-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.agenda__timeframe-header-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  margin-top: 15px;

  color: #5e348c;
}

.agenda__timeframe-header {
  font-weight: bold;
  font-size: 18px;

  text-align: center;

  margin-left: 15px;
  margin-right: 15px;

  min-width: 230px;
}

.agenda__days-container {
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.agenda-day__title {
  color: #5e348c;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-size: 18px;

  position: relative;
}

.agenda-day__today-indicator {
  position: absolute;
  left: -13px;
  top: 7px;

  width: 7px;
  height: 7px;
  background-color: #5e348c;
  border-radius: 50%;
}

.agenda-day__header-planning-date--overdue {
  color: red;
}

.agenda-day__title__day-name {
  font-weight: bold;
}

.agenda-day__headers-container {
  margin-top: 5px;
  margin-left: 20px;
}

.agenda-day__header-container {
  display: -webkit-flex;
  display: flex;

  font-family: Courier;

  margin-bottom: 8px;
}

.agenda-day__header__header-container {
  margin-top: 18px;
}

.agenda-day__header__planning-item-container {
  font-family: Courier;
}

.agenda-day__header-planning-type {
  min-width: 135px;
  color: gray;
}

.setting-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 10px;
  border-bottom: 1px solid #d8d8d8;
  padding: 5px 0;
}

.setting-container--vertical {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.settings-container a {
  text-decoration: none;
}

.setting-container:first-of-type {
  border-top: 1px solid #d8d8d8;
}

.setting-label {
  max-width: calc(100% - 90px);
}

.setting-container--vertical .setting-label {
  margin-bottom: 10px;
}

.setting-label__description {
  font-size: 14px;
  color: gray;
  max-width: calc(100% - 25px);
  margin-top: 5px;
}

.settings-buttons-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;

  margin-top: 20px;
}

.settings-btn {
  font-size: 20px;
  width: 90%;
  margin-bottom: 20px;
  box-sizing: border-box;
  text-align: center;

  position: relative;
}

.settings-button-separator {
  width: calc(100% - 20px);
  margin-top: 0px;
  margin-bottom: 20px;

  border: none;
  border-bottom: 1px solid #d8d8d8;
}

.changelog-badge-container {
  position: absolute;
  top: -10px;
  right: -10px;

  border-radius: 50%;
  background-color: red;
  width: 35px;
  height: 35px;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.settings-button-help-text {
  color: gray;
  padding-left: 20px;
  padding-right: 20px;

  margin-bottom: 10px;
  margin-top: -10px;
}

.default-deadline-warning-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
}

.default-deadline-value-textfield {
  width: 50px;
  margin-right: 10px;
}

.keyboard-shortcut-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 10px;
  border-bottom: 1px solid #d8d8d8;
  padding: 5px 0;
}

.keyboard-shortcut-container:first-of-type {
  border-top: 1px solid #d8d8d8;
}

.keyboard-shortcut-container__shortcut-name {
  max-width: calc(90% - 100px);
}

.keyboard-shortcut-container__shortcut-key {
  height: 34px;
  width: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

  color: #5e348c;
  background-color: #f1f1f1;
  border: 1px solid gray;
  box-sizing: border-box;

  font-family: Courier;
}

.keyboard-shortcut-container__shortcut-key--edit-mode {
  border: 2px solid #5e348c;
  background-color: white;
}

.keyboard-shortcuts-editor-container a {
  text-decoration: none;
}

.keyboard-shortcuts-editor__btn-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;

  margin-top: 20px;
}

.capture-templates-container {
  position: relative;
}

.new-capture-template-button-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;

  margin-top: 10px;
  margin-right: 10px;
}

.no-capture-templates-message {
  text-align: center;
  color: gray;
  padding: 20px;
}

.capture-template-container {
  border-bottom: 2px solid #5e348c;
  padding: 15px 5px;

  background-color: white;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.capture-template-container--dragging {
  background-color: #e6e6e6;
}

.capture-template-container__header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;

  color: #5e348c;
}

.capture-template-container__header__title {
  font-size: 20px;
  margin-left: -10px;

  max-width: calc(100% - 120px);
}

.capture-template-container__header__caret {
  margin-right: 15px;
  margin-left: 5px;

  -webkit-transition-property: -webkit-transform;

  transition-property: -webkit-transform;

  transition-property: transform;

  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
}

.capture-template-container__header__caret--rotated {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.capture-template-container__header__drag-handle {
  margin-left: auto;
  margin-right: 20px;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.capture-template-container__content {
  margin-top: 10px;
}

.capture-template__field-container {
  margin-bottom: 5px;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 5px;
}

.capture-template__field-container:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

.capture-template__field {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.capture-template__help-text {
  color: gray;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.capture-template__letter-textfield {
  width: 30px;
}

.capture-template__field__or-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

  color: lightgray;
  font-size: 14px;

  margin-bottom: 5px;
}

.capture-template__field__or-line {
  width: 20px;
  height: 1px;
  background-color: #d8d8d8;
  margin-left: 10px;
  margin-right: 10px;
}

.multi-textfields-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.multi-textfield-container {
  width: 75%;
  margin-top: 5px;
  pdisplay: flex;
}

.multi-textfield-field {
  font-family: Courier;

  -webkit-flex: 1 1;

          flex: 1 1;
}

.remove-multi-textfield-button {
  color: gray;
  border: 0;
  background-color: transparent;
}

.add-new-multi-textfield-button-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 5px;
  margin-right: 8px;
}

.add-new-multi-textfield-button {
  background-color: #5e348c;
  border: 0;
  color: white;
  width: 45px;
  height: 45px;
}

.template-textarea {
  border: 1px solid #d8d8d8;

  margin-top: 5px;
}

.capture-template__delete-button-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.capture-template__delete-button {
  background-color: #b90000;

  margin-top: 15px;
  margin-bottom: 0;
}

.sync-service-sign-in-container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.sync-service-sign-in__help-text {
  padding-left: 5px;
  padding-right: 5px;

  font-size: 18px;
  color: gray;

  text-align: center;
}

.sync-service-container {
  border-bottom: 1px solid lightgray;

  padding: 20px 10px;
}

.sync-service-container:last-of-type {
  border-bottom: none;
}

.dropbox-logo {
  width: 80%;
}

.google-drive-logo {
  width: 100%;
}

