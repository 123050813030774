.drawer-outer-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 3;
}

.drawer-outer-container--visible {
  background-color: rgba(94, 52, 140, 0.1);
}

.drawer-inner-container {
  position: fixed;
  bottom: -1px;
  left: -1px;
  right: -1px;

  padding: 15px 10px 0 10px;

  box-shadow: 0px -1px 5px 0px rgba(148, 148, 148, 0.55);
  border-bottom: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  background-color: white;

  max-height: 80%;
  overflow: auto;
}

.drawer__close-button {
  position: absolute;
  top: 15px;
  right: 15px;

  color: #5e348c;

  border: 0;
  background-color: transparent;
  padding: 0;
}

.drawer__grabber {
  width: 35px;
  height: 5px;
  background-color: gray;

  border-radius: 50px;

  margin-left: auto;
  margin-right: auto;

  margin-top: -5px;
  margin-bottom: 5px;
}

.drawer-modal__title {
  color: #5e348c;

  margin-top: 0;
}
