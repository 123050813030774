.capture-modal-header {
  display: flex;
  align-items: center;
}

.capture-modal-header-path {
  font-family: Courier;
  color: gray;

  margin-top: 5px;
}

.capture-modal-header-path--top-level {
  font-style: italic;
}

.capture-modal-textarea {
  border: 1px solid lightgray;
  font-family: Courier;

  margin-top: 10px;

  width: calc(100% - 5px);

  -webkit-appearance: none;
  border-radius: 0;
}

.capture-modal-button-container {
  display: flex;
  justify-content: space-between;

  margin-top: 10px;
}

.capture-modal-prepend-container {
  display: flex;
  align-items: center;
}

.capture-modal-prepend-label {
  margin-right: 10px;
}

.capture-modal-button {
  font-size: 16px;
}

.capture-modal-error-message {
  color: red;

  text-align: center;

  margin-top: 10px;
}
