.switch {
  display: inline-block;
  border: 1px solid #d8d8d8;
  width: 90px;
  height: 34px;
  padding: 2px;
  box-sizing: border-box;
}

.switch__grabber {
  width: 42px;
  height: 28px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  box-shadow: 1px 1px 5px 0px rgba(148, 148, 148, 0.75);
  background-color: white;
}
