.agenda-day__title {
  color: #5e348c;

  display: flex;
  justify-content: space-between;
  font-size: 18px;

  position: relative;
}

.agenda-day__today-indicator {
  position: absolute;
  left: -13px;
  top: 7px;

  width: 7px;
  height: 7px;
  background-color: #5e348c;
  border-radius: 50%;
}

.agenda-day__header-planning-date--overdue {
  color: red;
}

.agenda-day__title__day-name {
  font-weight: bold;
}

.agenda-day__headers-container {
  margin-top: 5px;
  margin-left: 20px;
}

.agenda-day__header-container {
  display: flex;

  font-family: Courier;

  margin-bottom: 8px;
}

.agenda-day__header__header-container {
  margin-top: 18px;
}

.agenda-day__header__planning-item-container {
  font-family: Courier;
}

.agenda-day__header-planning-type {
  min-width: 135px;
  color: gray;
}
