.setting-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  border-bottom: 1px solid #d8d8d8;
  padding: 5px 0;
}

.setting-container--vertical {
  flex-direction: column;
  align-items: flex-start;
}

.settings-container a {
  text-decoration: none;
}

.setting-container:first-of-type {
  border-top: 1px solid #d8d8d8;
}

.setting-label {
  max-width: calc(100% - 90px);
}

.setting-container--vertical .setting-label {
  margin-bottom: 10px;
}

.setting-label__description {
  font-size: 14px;
  color: gray;
  max-width: calc(100% - 25px);
  margin-top: 5px;
}

.settings-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 20px;
}

.settings-btn {
  font-size: 20px;
  width: 90%;
  margin-bottom: 20px;
  box-sizing: border-box;
  text-align: center;

  position: relative;
}

.settings-button-separator {
  width: calc(100% - 20px);
  margin-top: 0px;
  margin-bottom: 20px;

  border: none;
  border-bottom: 1px solid #d8d8d8;
}

.changelog-badge-container {
  position: absolute;
  top: -10px;
  right: -10px;

  border-radius: 50%;
  background-color: red;
  width: 35px;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.settings-button-help-text {
  color: gray;
  padding-left: 20px;
  padding-right: 20px;

  margin-bottom: 10px;
  margin-top: -10px;
}

.default-deadline-warning-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.default-deadline-value-textfield {
  width: 50px;
  margin-right: 10px;
}
