.header-content-container {
  white-space: pre-wrap;
  overflow-x: auto;
}

.header-content__edit-icon {
  color: #5e348c;

  margin-top: 10px;
  margin-right: 5px;
  float: left;
}

.header-content__insert-timestamp-button {
  color: gray;

  display: flex;
  align-items: center;
}

.insert-timestamp-icon {
  margin-right: 5px;
}
